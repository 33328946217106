
.dropdown .dropdown-menu {
    left:50px !important;
    -webkit-transition: all 600ms ease;
    transition: all 600ms ease;
    -moz-transition: all 200ms ease;
    -webkit-transition: all 0.0010ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    -o-transition: all 0.001ms ease;
    -ms-transition: all 2000ms ease;
  }
  
  .dropdown-content {
     
     width:150px;
    padding:15px 15px 15px 10px ;
    display: none;
    border: none;
    box-shadow: 0px 0px 6px 0px #f1f0f0;
  }

  .superoptions{
    border-top:2px solid rgb(197, 197, 197);
    border-left:2px solid rgb(197, 197, 197);
    border-right:2px solid rgb(197, 197, 197);
    text-align:center;
    padding:7px 0px;
    
    margin-left:15px;
    font-size:15px;
    font-family:montserrat,'sans-serif'
}
  
  .dropdown:hover .dropdown-menu {
    margin-left:-30px;
    width:50px;
    display: block;
    margin-top:-10px;
  }
  .dropdown-item {
    padding: 13px 2px !important;
    list-style:none;
    font-family:raleway,'sans-serif';
    color:rgb(97, 97, 97);
  }
  
  .dropdown-item:hover {
      text-decoration:none;
    color: rgb(2, 56, 87) !important;
   font-weight:400;
    list-style:none;
    font-family:raleway,'sans-serif';
  }

.subText{
    font-size:15px;
    margin-left:0px;
    
}.sidebar {
    background-color: #1e5b81;
    height: 90vh;
    margin-top: 0px;
    padding: 10px 0px;
    width: 260px;
    box-shadow: 0 2px 8px rgba(208, 207, 207, 0.25);
   
}
#sidebar1{
    display:block;
}
.sidebar-elementhead{
    font-size:13px;
    font-weight:500;
    padding:10px;
    
    font-family:Montserrat,'sans-serif';
    color:#ffffff;
    text-align:left;
    padding-right:40px;
    border-bottom:1px solid rgb(241, 241, 241);

}

.sidebar-element{
    margin:10px 0px -2px 0px;
    padding:14px 10px;
    font-size:13px;
    text-align:left;
    font-family:Montserrat,'sans-serif';
    color:rgb(255, 255, 255);
  font-weight:600;

}
.sidebar-subelement{
    
    padding:2px 10px 2px 46px;
    font-size:18px;
    text-align:left;
    font-family:Montserrat,'sans-serif';
    color:rgb(46, 46, 46);
  font-weight:600;
  display:none;
  

}
.sidebar-subelement:hover{
    
   cursor:pointer;


}

.sub-tabs{
    padding:10px;
    background-color:rgba(253, 253, 253, 0.103);
    
    border-radius:4px;
}

.sub-tabs:hover{
    padding:10px;
    background-color:rgb(245, 245, 245);
    
    border-radius:4px;
}
.sidebar-element:hover{
    margin:10px 0px -2px 0px;
    padding:14px 10px;
    font-size:13px;
    text-align:left;
    
   border-radius:0px;
   font-family:Montserrat,'sans-serif';
    color:rgb(255, 255, 255) !important;
    cursor:pointer;
}
.sidebar-sectionName{
    opacity:0.8;
    font-size:18px;
    padding:8px;
    text-align:left;
    font-weight:700;
    color:grey;
    font-family:Montserrat,'sans-serif';
}

.oneTab{
    margin:5px 0px;
}
.fa{
    
    padding:0px 6px;
}
.right{
    text-align:right;
}

.navbar-brand{
    padding:10px 10px 0px 10px;

    height:57px;
}
.subtext{
    color:#307AB2;
    font-size:14px;
    font-weight:500;
    font-family:montserrat,'sans-serif';
    margin-top:-5px;
    margin-left:8px;
}
.navbar {
    background-color: #ffffff;
    height: 90px;
    /* box-shadow: 0px -5px 10px #fdfdfd; */
    z-index: 9999;
    width: auto;
    margin-bottom: 0px !important;
    border-bottom:1px solid rgb(241, 241, 241);
}
.navbar-nav>li>a {
    padding-top: 26px;
    padding-bottom: 22px;
    
}

.tab-organisation {
    border-radius: 10px;
    margin: 15px 90px 10px 0px;
    padding: 5px;
    width: 286px;
    height: 200px;
    box-shadow: 9px 4px 12px #ebeaea;
    background-color: rgb(254, 255, 255);
    /* box-shadow: 3px 2px 8px 0px #dddddd; */
}

.mainScreen {
    background-color: #efefef;;
    height: 100vh;
}
.mainScreenView {
    background-color: #efefef;;
    height: 100vh;
}

.mainDiv{
    
  
    overflow-y:none;
    overflow-x:scroll;
    background-color:#f8f8f8;
}

.tabImage{
    width:140px;
}
.tab-row{
    margin-top:-20px;
}
.tab-text{
    font-family:Montserrat,'sans-serif';
    font-weight:600;
    font-size:14px;
}


/*form - css*/

.form-text{
    
    font-size:13px;
    font-family: Montserrat,'sans-serif';
    font-weight:500;
    
}


.table>tbody>tr>td, .table>tfoot>tr>td {
    padding: 4px 4px 4px 11px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid rgb(236, 236, 236);
    width:200px;
}
.form-textHead{
    font-size:13px;
    font-family:Montserrat,'sans-serif';
    font-weight:600;
    text-align:left !important;
   color:rgb(44, 43, 43);
   text-transform:uppercase;
   border-bottom:1px solid grey;
   
}

.badge{
    padding:10px 20px;
    background-color: green;
    border-radius:20px;
    box-shadow:0px 4px 6px 0px #8e8b8b;
}






.cell{
    padding:10px 10px;
    border-top:1px solid rgb(235, 235, 235);
    
    width:500px !important; 
    background-color:rgb(255, 255, 255);
    text-align: center;
}
.cell-head{
    padding:10px 10px;
   
    border-bottom:1px solid grey;
    width:auto; 
    background-color:rgb(255, 255, 255);

}
.busi-row{
    padding:10px;
    border-top:1px solid rgb(230, 230, 230);
}
.CardBody{
    border:1px solid rgb(236, 236, 236);
    
  }

    .red{
        background-color: red;
        
    }
    .link{
        padding-top:20px;
        color:blue;
        }
    
    .link:hover{
    color:rgb(5, 5, 126);
    }

    a { color: inherit; } 

    a:hover {
        color: #ffffff;
        text-decoration: none;
    }

    a:active {
        color: #ffffff !important;
        text-decoration: none;
    }
    .header-div{
        height:220px;
        box-shadow: 1px 3px 11px #d8d8d8;
    padding:10px 30px;
background-color: white;
z-index:5000;}


.header-divi{
    height: 100px;
    padding: 10px 30px;
    background-color: #f6f6f6;
}

.header-divi1{
    height:120px;
  
padding:10px 30px;
background-color: white;}

.head-name{font-weight:400;
    font-size:23px;
    text-align:left;
}

.head-mail{
    font-weight:400;
    font-size:14px;
    text-align:left;
    font-family:raleway,'sans-serif';
}
.head-mail-important{
    font-weight:600;
    font-size:16px;
    text-align:left;
    font-family:raleway,'sans-serif';
}

.head-button{
    margin-top:20px;
    font-weight:400;
    font-size:14px;
    text-align:left;
}

.dedicated-page{
    margin-right:36px;
    overflow-x:hidden;
    height:660px;
    margin-left:-52px;
    background-color: white;
    box-shadow: -1px 2px 10px 0px #ebebeb;
}
.dedicated-page1 {
    margin-right: 36px;
    overflow-x: hidden;
    height: 620px;
    margin-left: -44px;
    background-color: white;
    border: 1px solid #f3f0f0;
}
.Name-tag{
    color:#3f3f3f;
    font-size:14px;
    font-weight:600;
}
.dedicated1-page{
    margin-right:36px;
    overflow-x:hidden;
    height:620px;
    background-color: white;
    margin-left:-30px;
    border:1px solid rgb(233, 233, 233);
}
#section{
    
    overflow-y: scroll;
    height:700px;
    border:1px solid rgb(235, 235, 235);
    margin-right:20px;
}
.content-div{
    padding:2px 60px;
    
}

.control-label{
   
        font-size:13px;
        font-weight:500;
        font-family:montserrat,'sans-serif';
        margin-top:1px;
    
}

.content1-div{
    padding:30px 60px 20px 60px;
}
.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
  }

.Div{
    
    margin-right:40px;
    margin-left:-50px;
}
.options8{
   
    text-align:center;
    padding:8px 10px 8px 10px;
    margin-left:0px;
    
    font-size:14px;
   
    font-family:montserrat,'sans-serif'
}
.account-head {
    color: rgb(53, 53, 53);
    font-family: raleway,'sans-serif';
    font-weight: 400;
    margin-top: -6px;
    font-size: 20px !important;
    font-family: montserrat,'sans-serif';
    margin-bottom: 30px;
    /* margin-left: 30px; */
    background-color: #E5EEEF;
    padding: 10px 46px;
    font-weight: 600;
}

.active2{
    color:rgb(33, 108, 194) !important;
     font-weight:600 !important;
 }
 .active1{
    border-top: 2px solid rgb(73, 133, 189) !important;
    border-left: 2px solid rgb(73, 133, 189) !important;
    border-right: 2px solid rgb(73, 133, 189) !important;
     font-weight:600px;
 }
 
.add-header {
    font-weight: 600;
    margin-bottom: 50px;
    margin-top: 40px;
    font-size: 18px;
    color: rgb(32, 32, 32);
    font-family: montserrat,'sans-serif';
    background-color: #e5eeef;
    padding: 10px 49px;
}

.options8{
   
  cursor:pointer
}

.list-head{
    color:white;
    font-family:raleway,'sans-serif';
    font-size:18px;
    font-weight:600;
    text-align:left;
    margin-bottom:40px;
    border-radius:4px;
    border-bottom: 1px solid #eeeeee;
    padding:10px 10px;
    margin-left:-15px;
    background-color:#1E5B81;
}
.section{
    margin-left:-8px;
   
}

.detail-fields {
    font-family: Montserrat,'sans-serif';
    font-weight: 600;
    font-size: 13px !important;
    margin-top: 0px;
    color: rgb(56, 56, 56);
    background-color: #e8e9e930;
    /* border: 1px solid; */
    border: 1px solid #e5efef;
    padding: 8px;
    text-align: center;
}

.head-button1{
    margin-top:20px;
    border-radius:6px;
    padding:2px;
    color:rgb(59, 59, 59);
    font-weight:600;
   
    border:2px solid rgb(216, 253, 194);
}

.payStatus{
    background-color: transparent;
    border: none;
    font-size: 13px;
    margin-top: 6px;
 }
 .form-group {
    padding: 20px;
}
 
 .processStatus{
    background-color: transparent;
    border: none;
    font-size: 13px;
    margin-top: 6px;
 }
 
 .head-button2 {
    margin-top: 6px;
    border-radius: 6px;
    font-weight: 600;
    padding: 2px;
    color: #515050;
    border:2px solid rgb(194, 225, 253);
    
}

.sidebar-link{
    color:rgb(255, 255, 255) !important;
    text-decoration: none !important;
    padding-bottom:5px;
    font-size:13px;
    font-weight:600;
   
}
.sidebar-link:active{
    color:white !important;

}
.sidebar-link:hover{
    color:rgb(245, 245, 245)!important;
    
}
.tablebusi{
    width:auto;
}
.dedicated-div{
    
}

.tab-image {
    height: 58px;
    margin-top: 45px;
}

li{
    text-decoration:none !important;
}

.tab {
    border-radius: 10px;
    margin: 10px 0px;
    padding: 5px;
    width: 176px;
    height: 148px;
    
    background-color: #93d7f8;
    height: 178px;
   
    box-shadow: 3px 2px 8px 0px #e4e9e9;
}
.tab:hover{
    
    border-radius:10px;
    margin:10px 0px;
    padding:5px;
    background-color: rgb(164, 231, 252);
    box-shadow: 3px 2px 8px 0px #abd2ce
}
.tab-row{
    padding:10px;
    border-top:5px solid rgb(248, 248, 248);
    border-bottom:5px solid rgb(248, 248, 248);
    margin-top:10px;
}
.tab-row1{
    padding:10px;
  
    margin-top:10px;
}
.tab-row-head{
font-size:20px;
color:rgb(68, 68, 68);
font-family:montserrat,'sans-serif';
}

.Account-Card{
    margin:10px 120px;
    padding:20px;
    border:2px solid rgb(228, 228, 228);
    border-radius:10px;
    height:500px;
}
.account-head{
   color:#1E5B81;
   font-family: raleway,'sans-serif';
   font-weight:400;
   margin-bottom:30px;
}
.account-info{
    color:rgb(22, 31, 36);
    font-family: raleway,'sans-serif';
    
    font-weight:500;
    font-size:17px;
    padding:10px 0px;
 }
 .account-col1{
  
    padding-top:60px;
  
 }
 .account-col2{
   
    margin-top:30px;
  
 }

 .info-column{
     
 }

 

 .nav-item{
     
     margin-right:-20px;
    font-size:15px;
    font-family:raleway,'sans-serif';
    color:rgb(26, 26, 26) !important;
}
.nav-item1{
    margin-right:10px;
   font-size:14px;
   font-family:raleway,'sans-serif';
   color:rgb(26, 26, 26) !important;
}
.nav-item:hover{
    color:black;
    
}

.nav-item:active{
    color:black;
   
}

.tableDiv {
    height: 70vh;
    overflow: auto;
    display: block;
}

.myTable{
    height: 74vh;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{

    border: none !important;
}

.ReactTable .rt-thead.-header{
    box-shadow: 0 0px 0px 0 rgba(0,0,0,0.15) !important;
}

.ReactTable{
    position: relative;
    display: flex;
    flex-direction: column;
    border:none !important;
    /* border: 1px solid rgba(0,0,0,0.1); */
    box-shadow: 0 2px 8px rgba(208, 207, 207, 0.25);
    border-radius: 5px;
    padding:10px;
    background-color: white;
    margin-left: 6px;
}

.fieldbox{
    box-shadow:none;
}
input[type=text], input[type=password] {
    background-color: #ffffff !important;
    color: #444444 !important;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin: 5px;
    border:1px solid rgb(226, 226, 226);
    width: 85%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    border-radius: 5px 5px 5px 5px;
}
.rt-td {
    border-top: 1px solid rgb(248, 248, 248) !important;
  
    border-left: 1px solid rgb(248, 248, 248) !important;
    border-right: 1px solid rgb(248, 248, 248) !important;
    height:140px;
    overflow-y:scroll !important;
}

.content1-div > input[type=text], input[type=password] {
        background-color: #ffffff !important;
        color: #444444 !important;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        margin: 5px;
        border:1px solid rgb(209, 209, 209);
        width: 98%;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        border-radius: 5px 5px 5px 5px;
    }

    textarea {
        background-color: #ffffff !important;
        color: #444444 !important;
        padding: 7px 10px;
        height: 122px;
        border: 1px solid #dadada;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        margin: 4px 12px 0px 5px;
        /* margin: 1px 0px 0px 0px; */
       
        width: 85%;
        -webkit-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        border-radius: 5px 5px 5px 5px;
    }
    input, button, select, textarea {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }
.button1{
    margin-top:30px;
    background-color: #1E5B81;
    border: none;
    color: white;
    padding: 10px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius:5px;
    cursor: pointer;
}

.dashboardHead{
    font-size:18px;
    font-weight:500;
    border-bottom:1px solid #e6e6e6;
    padding-bottom:15px;
    margin-left:5px;
    font-family:montserrat,'sans-serif'
}
.dashboardCard {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #efefef;
    padding: 20px;
    margin-left: -20px;
    width: 79vw;
}
/* FOR DASHBOARD CARDS */
.card-counter{
    box-shadow: 2px 2px 10px #DADADA;
    margin: 5px;
    padding: 20px 10px;
    background-color: #fff;
    height: 140px;
    border-radius: 5px;
    -webkit-transition: .3s linear all;
    transition: .3s linear all;
  }

  .card-counter:hover{
    box-shadow: 4px 4px 20px #DADADA;
    -webkit-transition: .3s linear all;
    transition: .3s linear all;
  }

  .card-counter.primary{
    background-color: rgb(224, 188, 27);
    color: #FFF;
  }

  .card-counter.primary1{
    background-color: rgb(8, 196, 243);
    color: #FFF;
  }
  .card-counter.danger{
    background-color: #ef5350;
    color: #FFF;
  }  

  .card-counter.success{
    background-color: #66bb6a;
    color: #FFF;
  }  

  .card-counter.info{
    background-color: #26c6da;
    color: #FFF;
  }  

  .card-counter i{
    font-size: 5em;
    opacity: 0.5;
  }

  .card-counter .count-numbers{
    position: absolute;
    right: 35px;
    top: 20px;
    font-size: 46px;
    display: block;
    font-weight:600;
  }

  .card-counter .count-name{
    position: absolute;
    right: 35px;
    top: 100px;
   color:white;
    text-transform: capitalize;
    opacity: 1;
    display: block;
    font-size: 18px;
    font-weight:600;
  }

  .fa-ticket{
      color:white;
  }
  /* END FOR DASHBOARD CARDS */

  /*TIMELINE*/

  .timeline {
    list-style-type: none;
    position: relative;
    z-index: 0;
}
.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 0;
}
.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
    z-index: 0;
}
.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #919191;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 0;
}

.update {
    list-style-type: none;
    position: relative;
    z-index: 0;
}
.update:before {
    content: ' ';
    background: #22c0e8;
    display: inline-block;
    position: absolute;
    left: 34px;
    width: 2px;
    height: 100%;
    z-index: 0;
}
.update > li {
    margin: 20px 0;
    padding-left: 20px;
    z-index: 0;
}
.update > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 30px;
    height: 30px;
    z-index: 0;
}

.proposals{
    list-style-type:none !important;
}

.float-right{
    float: right;
}

.float-left{
    float: left;
}

.attachment{
    padding-top:10px;
    background-color:rgb(255, 255, 255);
    border:1px solid rgb(196, 196, 196);
    font-size:15px;
    color:rgb(73, 73, 73);
}
#myFileInput {
    display:none;
}


.mail-head{
font-size:14px;
font-weight:500;

font-family:'montserrat', sans-serif;
}

.mail-subhead{
    font-size:14px;
    font-weight:400;
font-family:'montserrat', sans-serif;
}

.options-row {
    height: 60px;
    padding: 8px 40px;
    background-color: rgb(238, 238, 238);
     border-bottom: 1px solid #e7e7e7; 
}



.send-button{
    background-color: #1D5A81;
    width:200px;
    border:none;
    font-size:18px;
    margin-top:50px;
}


.fieldbox1{
    width:900px;
}

.montserrat{
    font-family:montserrat,'sans-serif'
}

.timeline-head{
    font-size:17px;
    font-weight:600;
}

.timeline-subhead{
    font-size:12px;
    font-weight:400;
}

.timeline-card{
    
    padding:5px 20px;
    border-radius: 5px;
    width:800px;
    
    margin-left:30px;
}

.update-card{
    
    padding:5px 20px 30px 40px;
   
    
    width:100%;
}

.mail-card{
    border-left:1px solid  rgb(231, 231, 231);
    border-right:1px solid  rgb(233, 233, 233);
    border-bottom:1px solid  rgb(245, 245, 245);
    padding:10px 20px 10px 10px;
   
    
    width:100%;
}

.timeline-card > a:hover{
    color:black !important;
}

.timeline-elements:hover{
    color:black;
}

.mail-grid-button{
    background-color: #1D5A81;
    width:60px;
}

.notification-number {
    background-color: #ea3f33;
    border-radius: 50px;
    height: 18px;
    font-family: arial,sans-serif;
    margin-left: -8px;
    width: 18px;
    font-weight: 600;
    position: absolute;
    font-size: 11px;
    margin-top: -11px;
    color: #fff;
    padding: 2px 0px 0px 3px;
    margin-bottom: 0px;
    border: 2px solid rgb(240, 125, 125);
}

.notify-button{
    background-color: red;
    border-radius:50px;
   height:25px;
   margin-left:-15px;
   width:25px;
   font-size:13px;
   padding:0px;
    margin-bottom:30px;
    border:2px solid rgb(240, 125, 125);
}

.notify{
    padding-left:50px;
}

.bell-icon{
    height:25px;
}

.options{
    text-align:center;
    padding:7px 0px;
    color:rgb(39, 39, 39);
    font-size:14px;
    font-family:montserrat,'sans-serif'
}

.document-card{
    margin:40px 30px;
    padding:20px;
    background-color:rgb(255, 255, 255);
    font-weight:700px;
    height:120px;
    font-size:17px;
    border-radius:10px;
    border:1px solid rgb(223, 223, 223);
}

.options{
    cursor:pointer;
}

.header{
    background-color:rgb(255, 255, 255);
    font-family: montserrat,'sans-serif';
    padding:20px 40px;
    font-size:24px;
    
    border-bottom:1px solid rgb(224, 224, 224);
    z-index:-5;
}

.header2{
    background-color:rgb(255, 255, 255);
    font-family: montserrat,'sans-serif';
    padding:20px 20px;
    font-size:24px;
    
    border-bottom:1px solid rgb(224, 224, 224);
    z-index:-5;
}


.doc-section{
    background-color: #ffffff;
    border:1px solid rgb(226, 226, 226);
    height:auto;
    overflow:scroll;
}

.table-head{
    font-size:14px;
    margin-left:60px;
    font-family: montserrat,'sans-serif';
   font-weight:600;
   width:225px;
   padding:10px;
   word-break:break-word !important;
   background-color: white;
   text-align:center;
    
}

.table-item {
    margin: 2px 10px;
    font-size: 14px;
    padding: 10px 5px;
    width:225px;
    word-break:break-word !important;
    text-align: center;
    background-color: #e8e9e930;
    font-family: montserrat,'sans-serif';
}

.editButton{
    background-color: rgb(255, 208, 0) !important;
   border:none;
   padding:0px 0px 0px 10px;
   width:55px;
   color:black;
}
.header1{
    background-color:rgb(255, 255, 255);
    font-family: montserrat,'sans-serif';
    padding:20px 40px;
    font-size:24px;
    font-weight:700;
    border-bottom:1px solid rgb(224, 224, 224);
    z-index:10;
}
.deleteButton{
    background-color: #ed0e0e !important;
    border:none;
    padding:0px 0px 0px 10px;
    width:55px;
    color:white !important;
}

.TrashButtonColumn{
    margin-left: 10px;
   border:1px solid rgb(80, 80, 80);
   padding:5px 10px 5px 3px !important;
   width:35px;
   background-color: white !important;
   color:black;
}

.url{
    background-color: rgb(255, 102, 55) !important;
   border:none;
   border-radius:0px;
   padding:0px 0px 0px 10px;
   width:55px;
   font-weight:600;
}
.add-button{
    background-color: rgb(48, 236, 158) !important;
   border:none;
   margin-left:10px;
   border-radius:3px;
   font-size:15px;
   padding:5px 0px 5px 20px;
   width:150px;
}
.saveButton{
    background-color: rgb(24, 192, 128) !important;
   border:none;
   padding:0px 0px 0px 10px;
   width:55px;
}

.table-head1{
    font-size:14px;
    margin-left:60px;
    font-family: montserrat,'sans-serif';
   font-weight:600;
 
   padding:10px;
   background-color: white;
   text-align:center;
    width:50px;
}

.table-item1 {
    margin: 2px 10px;
    font-size: 14px;
    padding: 10px 24px;
    border: 1px solid rgb(198, 206, 207);
    text-align: center;
   
    font-family: montserrat,'sans-serif';
}
.doc-head-row{
    padding:10px 0px;
    border-bottom:1px solid rgb(230, 230, 230);
}

ul{
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
}

.date-text{
font-size:15px;
font-weight:400;
font-family:Montserrat,'sans-serif';
}

.from-text{
    font-size:17px;
    font-weight:400;
    font-family:Montserrat,'sans-serif';
}
.message-text{
        font-size:16px;
        font-weight:400;
        font-family:Montserrat,'sans-serif';
        margin:10px 0px 0px 0px;
        }
.subject-text{
    margin:-30px 0px 0px -20px;
            font-size:24px;
            font-weight:600;
            font-family:Montserrat,'sans-serif';
            }

.RED-mail{
    background-color: rgb(255, 193, 193);
}


.GREEN-mail{
    background-color: rgb(215, 255, 205);
}

.PURPLE-mail{
    background-color: rgb(138, 52, 250);
}

.YELLOW-mail{
    background-color:rgb(255, 251, 193);
}



.BLUE-mail{
    background-color: rgb(213, 235, 253);
    
}


.message-box{
    padding: 10px 30px !important;
    
    display: flex;
    width:430px;
    z-index:10;

}

a:hover{
color:inherit !important;
}

.panel-buttons{
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    color: black;
    font-size: 15px;
    border:1px solid grey;
    margin-bottom: 10px;
    margin-top: 50px;
    font-family:Montserrat,'sans-serif';
}

.back-buttons{
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    color: black;
    font-size: 15px;
    border:1px solid grey;
    margin-bottom: 10px;
    margin-top: 0px;
    font-family:Montserrat,'sans-serif';
}

.butn{
    cursor:pointer;
}

.modal{
    z-index:10;
}
.cross-row{
    padding:4px;
    background-color: rgb(233, 233, 233)
}

.upload-box{
    font-size: 16px;
    margin-top: 2px;
    margin-top: 1px;
    border-radius: 3px;
    padding: 13px;
    background-color: white;
    padding: 7px;
    border: 1px solid #c5c5c5;
}
.chat-button {
    background-color: #fafafa;
    border-radius: 50px;
    height: 40px;
    border: none;
    width: 170px;
    color: #3f3f3f;
    font-weight: 600;
    font-size: 17px;
    margin-left: 70px;
    margin-top: 60px;
    box-shadow: 0px 0px 1px 1px #dedede;
}

.button-type{
    border:1px solid rgb(216, 214, 214);
    color:rgb(32, 32, 32);
    padding:7px 8px 0px 8px;
    border-radius:5px;
}

.masterMail-page{
    
    height:590px;
    overflow-y:scroll;
}

a:hover{
    color:inherit !important;
}
a:active{
    color:inherit !important;
}


.tooltip {
    
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
 .tooltiptext {
     width: 120px;
    background-color: rgba(0, 0, 0, 0);
    color: rgb(32, 32, 32);
    font-size:13px;
    margin-left:-40px;
    margin-top:2px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    display:none;
    /* Position the tooltip */
    position: absolute;
    z-index: 5;
  }
  
  .nav-item:hover  .tooltiptext{
      display:block;
  }

  .loading{
    position: fixed;
    width: 76vw;
    height: 100vh;
    padding-top: 20%;
    z-index: 9;
    text-align: center;
  }

  .timeline-head{
    width: 100%;
    word-break: break-all;
  }

.text-box{

        border: 1px solid rgb(196, 196, 196);
        font-size: 17px;
        padding: 10px 73px;
        width: 85%;
 
  }