
/* BASIC */
  
  
  /* STRUCTURE */
  
  .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column; 
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 20px;
  }
  
  
  #formContent {
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 0px;
    box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
    text-align: center;
  }
  
  #formFooter {
    background-color: #f6f6f6;
    border-top: 1px solid #dce8f1;
    padding: 25px;
    text-align: center;
    border-radius: 0 0 10px 10px;
  }
  
  
  
  
  
  /* FORM TYPOGRAPHY*/
  
  input[type=button], input[type=submit], input[type=reset]  {
    background-color: rgb(17, 58, 119);
    
    color: white;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    
    font-size: 14px;
    box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 10px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
    background-color: #39ace7;
  }
  
  input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  
  input[type=text],input[type=password] {
    background-color: #f6f6f6;
    
    color: #0d0d0d;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 5px;
    width: 85%;
   
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    border-radius: 5px 5px 5px 5px;
  }
  
  input[type=text]:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
  }
  
  input[type=text]:placeholder {
    color: #cccccc;
  }
  
  
  
  /* ANIMATIONS */
  
  /* Simple CSS3 Fade-in-down Animation */
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  /* Simple CSS3 Fade-in Animation */
  @-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
  @keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
  
  .fadeIn {
    opacity:0;
    -webkit-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;
  
    -webkit-animation-fill-mode:forwards;
    animation-fill-mode:forwards;
  
    -webkit-animation-duration:1s;
    animation-duration:1s;
  }
  
  .fadeIn.first {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  
  .fadeIn.second {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  
  .fadeIn.third {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }
  
  .fadeIn.fourth {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  
  .fa{
    font-size:13px ;
    padding:0px 6px;
}
  /* Simple CSS3 Fade-in Animation */
  .underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #56baed;
    content: "";
    -webkit-transition: width 0.2s;
    transition: width 0.2s;
  }
  
  .underlineHover:hover {
    color: #0d0d0d;
  }
  
  .underlineHover:hover:after{
    width: 100%;
  }
  
  
  
  /* OTHERS */
  
  *:focus {
      outline: none;
  } 
  
  #icon {
    width:31%;
    margin-bottom:40px;
    margin-top:10px;
  }
  

  .userImage{
      padding:50px;
      height:200px;
      width:100px;
  }

  .logo{
      margin-top:30px;
  }

  .hi-text{
    margin-top:10px;
      font-size:17px;
      font-weight:400;
      color:rgb(54, 54, 54);
      margin-bottom:40px;
  }

  .registerbtn{
    margin-top:5px;
    color:rgb(236, 113, 113);
  }

  .userImage1{
height:130px;
width:60%;
  }

  .btn-google {
    color: #fff;
    background-color: #dd4b39;
    border-color: rgba(0,0,0,0.2);
    padding: .55
    5rem .75rem;
  }
  
  .btn-google:hover {
    color: #fff;
    background-color: #f35c4e;
    border-color: #e6e6e6;
  }
  .btn-linkedin {
    color: #fff;
    background-color: #2053fa;
    border-color: rgba(0,0,0,0.2);
    padding: .55
    5rem .75rem;
  }
  
  .btn-linkedin:hover {
    color: #fff;
    background-color: #194bee;
    border-color: #e6e6e6;
  }
  
  .btn-google:focus, .btn-google.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  }
  
  .btn-google.disabled, .btn-google:disabled {
    color: #fff;
    background-color: #ea4335;
    border-color: #fff;
  }
  
  .btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active,
  .show > .btn-google.dropdown-toggle {
    color: #fff;
    background-color: #d62516;
    border-color: #dfdfdf;
    padding: .550rem .75rem;
  }
  
  .btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus,
  .show > .btn-google.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  }
  
  .login-text{
    margin-top:5px;
    font-size:13px;
    font-weight:600;
  }
